import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/global.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from "axios";

const app = createApp(App)


for (let iconName in ElementPlusIconsVue) {
    app.component(iconName, ElementPlusIconsVue[iconName])
}


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


//登录拦截
// router.beforeEach((to,from,next)=>{
//     if (to.meta.requireAuth) {//requireAuth若为true则该路由需要判断是否登录
//         if (sessionStorage.getItem("user")) {//判断当前的userName数据是否存在
//             next();
//         }
//         else {
//             next({//返回登录页面
//                 path: '/login',
//                 query: {redirect: to.fullPath}
//             })
//         }
//     }
//     else {
//         next();
//     }
// })
axios.interceptors.request.use(
    config => {
        var token = localStorage.getItem("Authorization");
        config.headers.Authorization = token;
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// http response 拦截器 添加一个响应拦截器
axios.interceptors.response.use((response) => {
    // 响应成功，根据后台返回数据统一处理错误码
    if (response.data.code == "400001") {
        // alert("登录超时请重新的登录！")
        router.push("/login")
    } else if (response.data.code == "400002") {
        alert("认证失败！")
        router.push("/login")
    } else if (response.data.code == "400003") {
        alert("权限不足！")
        // router.push("/404")
    }
    return response;
}, (error) => {
    // 响应失败
    router.push("/login")
    return Promise.reject(error);
})


//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    //如果路由需要跳转
    if (to.meta.requireAuth) {
        //判断 如果school本地存储是qinghuadaxue的时候，可以进去
        if (localStorage.getItem('Authorization') != null) {
            next()  //放行
        } else {
            router.push("/login")
            // alert('抱歉，请重新登录！')
        }
    } else {
        // 否则，放行
        next()
    }
})
app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
    size: 'small'
}).mount('#app')
