import { createStore } from 'vuex'

export default createStore({
  getters: {
  },
  //首先我们在state里定义一个token变量
  state: {
    token:''
  },

  //然后在mutations里，写一个token赋值的方法
  mutations: {
    // SET_TOKEN 是方法名
    SET_TOKEN: (state, token) => {
      state.token = token
    },
  },



  actions: {
    loginFn({ commit }, token) {
      return new Promise((resolve, reject) => {
      // 这里就是把token存到了xuex中了
      commit('SET_TOKEN', token)
  })
},
},
  modules: {
  }
})
