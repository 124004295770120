import {createRouter, createWebHistory} from 'vue-router'
import Layout from "@/layout/Layout";

const routes = [
    {

        meta: {
            requireAuth: true
        },
        path: '/',
        name: 'layout',
        component: Layout,
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/HomeView')
            },
            {
                path: 'data',
                name: 'data',
                component: () => import('@/views/Data')
            },
            {
                path: 'sendMessage',
                name: 'sendMessage',
                component: () => import('@/views/SendMessage')
            },
            {
                path: 'groupManage',
                name: 'groupManage',
                component: () => import('@/views/ManageGroups.vue')
            },
            {
                path: 'buyerInfo',
                name: 'buyerInfo',
                component: () => import('@/views/BuyerInfo')
            },
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/official/HomeView.vue')
            },
            {
                path: 'timestamp',
                name: 'timestamp',
                component: () => import('@/views/official/TimeStampView.vue')
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/views/official/AboutUsView.vue')
            },
            {
                path: 'show',
                name: 'show',
                component: () => import('@/views/official/StoreShow.vue')
            },
            {
                path: 'brandVideo',
                name: 'brandVideo',
                component: () => import('@/views/official/BrandVideo.vue')
            },
            {
                path: 'mediaInfo',
                name: 'mediaInfo',
                component: () => import('@/views/official/MediaInfo.vue')
            },
            {
                path: 'brandAction',
                name: 'brandAction',
                component: () => import('@/views/official/BrandAction.vue')
            },
            {
                path: 'allGood',
                name: 'allGood',
                component: () => import('@/views/official/AllGood.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login')
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router

