<template>
  <div>

    <div id="menu">

      <el-menu
          :collapse="isCollapse"
          :default-active="this.$router.path"
          class="el-menu-vertical-demo"
          router
      >
        <el-sub-menu v-if="role === 1
        || role === 0 || role === 2 || role === 3" index="1">
          <template #title>
            <el-icon>
              <document/>
            </el-icon>
            <span>数据信息</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="home">数据统计</el-menu-item>
            <el-menu-item index="data">数据管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="role === 9 || role === 0" index="2">
          <template #title>
            <el-icon>
              <location/>
            </el-icon>
            <span>推送管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="sendMessage">推送服务</el-menu-item>
            <el-menu-item index="groupManage">分组管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>


        <el-sub-menu v-if="role === 10 || role === 0" index="3">
          <template #title>
            <el-icon>
              <setting/>
            </el-icon>
            <span>官网管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="home">主页静态图片</el-menu-item>
            <el-menu-item index="timestamp">时间戳图片</el-menu-item>
            <el-menu-item index="about">关于我们静态图片</el-menu-item>
            <el-menu-item index="show"> 门店展示静态图片</el-menu-item>
            <el-menu-item index="brandVideo"> 品牌视频图片</el-menu-item>
            <el-menu-item index="mediaInfo"> 媒体资讯图片</el-menu-item>
            <el-menu-item index="brandAction"> 品牌动向图片</el-menu-item>
            <el-menu-item index="allGood"> 公益活动图片</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>


        <el-menu-item index="#">

          <el-button style="border: 0;width: 100%;height: 100%;font-size: 14px;"
                     @click="isCollapse = !isCollapse">
            <el-icon v-if="isCollapse">
              <DArrowRight/>
            </el-icon>
            <el-icon v-if="!isCollapse">
              <DArrowLeft/>
            </el-icon>
            {{
              this.isCollapse ? '' : '收起'
            }}
          </el-button>

        </el-menu-item>
      </el-menu>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Aside",
  data() {
    return {
      isCollapse: false,
      role: '',
    }
  },
  created() {
    let that = this
    axios.get('/api/buyer/role').then(res => {
      if (res.data.code === "000000") {
        that.role = res.data.data
      } else {
        that.$message.error('网络异常，请稍候再试！')
      }
    })
  }
}
</script>

<style scoped>

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

#menu span {
  font-family: '微软雅黑'
}

</style>
