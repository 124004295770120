<template>
  <router-view/>
</template>

<style>
html, body, #app {
  height: 100%;
}
</style>
<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";

export default {
  name: "App",
}

</script>
