<template>
    <div style="height: 60px; line-height: 50px;border-bottom: 1px solid #ccc;display: flex">
        <div style="width: 200px;padding-left: 30px;font-weight: bold;color: grey;font-size: 20px">供应商管理系统</div>
        <div style="flex: 1"></div>

            <div>
                <el-avatar size="large"> {{ this.buyerName }}</el-avatar>
            </div>
            <!--    <div style="margin-top: 2px">-->
            <!--      <el-icon size="large" style="width: 25px;height: 25px" >-->
            <!--        <User />-->
            <!--      </el-icon>-->
            <!--      -->
            <!--    </div>-->
            <!--    <span>-->
            <!--        用户:aaa-->
            <!--      </span>-->
            <div style="width: 100px;padding-top: 20px;margin-left: 10px">
                <el-dropdown>
                  <span class="el-dropdown-link">
                <el-icon class="el-icon--right">
                  <arrow-down/>
                </el-icon>
                </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="this.$router.push('buyerInfo')">个人信息</el-dropdown-item>
                            <el-dropdown-item @click="logout">注销</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>

    </div>
</template>

<script>
import {ArrowDown} from '@element-plus/icons-vue'
import axios from "axios";

export default {
    name: "Header",
    data() {
        return {
            userData: localStorage.getItem("token"),
            buyerName: '',
        }
    },
    methods: {
        logout() {
            sessionStorage.removeItem("user")
            axios.post("/api/logout").then(res => {
                    this.$router.push("/login")
                    this.$message.success("注销成功")
                sessionStorage.setItem('loginTime',0)
                }
            )
        },
    },
    created() {

    },
    mounted() {
        axios.get("/api/initBuyer").then(res => {
            this.buyerName = res.data.data.buyerName
            localStorage.setItem('buyerName', res.data.data.buyerName)
            localStorage.setItem('buyerRole', res.data.data.role)
        })
    }
}
</script>

<style scoped>
/*.example-showcase .el-dropdown-link {*/
/*  cursor: pointer;*/
/*  color: var(--el-color-primary);*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/
</style>
